body{
    background: #000;
    color: #fff;
}
.videoWrapper{
    float: left;
    height: 100vh;
    width: 100%;
}
.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  .video-background video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
  }
  .overlayVideo{
    background-color: rgba(0, 0, 0, 0.80);
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .overlayVideo h1{
    position: absolute;
    top: 50%;
    width:100%;
    text-align: center;
    color: #F42664;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 50px;
    text-shadow: 2px 2px 7px rgba(255,255,255,0.15);
}
.modulo{
    padding: 40px 0;
    float: left;
    width: 100%;
}
.modulo img{
    max-width: 100%;
}
.modulo h2{
    color: #F42664;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-weight: bold;
}