footer{
    float: left;
    width: 100%;
    padding: 10px 0;
    background: rgba(17, 10, 10, 0.95);
}
footer p{
    text-align: center;
    margin-bottom: 0;
    color: #fff;
    font-size: 12px;
}