.contactFromWrapper label{
    margin-bottom: 5px;
    margin-top: 15px;
}
.form-group button{
    background: #F42664;
    color: #fff;
    font-weight: bold;
    border: none;
    outline: none;
}