@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600&display=swap');
header{
    position: fixed;
    width: 100%;
    background: rgba(17, 10, 10, 0.95);
    height: 80px;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    z-index: 3;
}
.logo{
    width: 200px;
    margin: 10px;
}
.wrapper-header{
    display: flex;
    align-items: center;
    width: 100vh;
}
.menu{
    display: flex;
    list-style: none;
    margin:0;
    font-family: 'Fira Sans', sans-serif;

}
.menu li{
    margin-right: 10px;
}
.menu li a{
    color: #fff;
    text-decoration: none;
}
.trigger-menu{
    color: #fff;
    font-size: 20px;
    position: absolute;
    right: 1em;
    display: none;
}
.trigger-menu:hover{
    color: #fff;
    opacity: 0.9;
}
.trigger-search{
    color: #fff;
    font-size: 20px;
    position: absolute;
    right: 1em;
    width: 30px;
    height: 45px;
    display: none;
}
.trigger-search:hover{
    color: #fff;
    opacity: 0.9;
}
.social-icon{
    font-size: 20px;
}
.searchContainer{
    width: 100%;
    position: absolute;
    top: 80px;
    background: #000;
    max-height: 0; /* hide the menu initially */
    overflow: hidden; /* hide the overflow content */
    transition: all 0.3s ease-out; /* add transition property */
}
.searchContainer.show {
    max-height: 100%; /* set a maximum height for the menu */
}
.searchInputWrapper{
    width: 90%;
    margin:10px auto;
    height: 40px;
}
.searchInputWrapper form{
    display: block;
    height: 100%;
}
.searchInput{
    width: 100%;
    height: 100%;
    border: 1px solid rgb(213, 212, 212);
    opacity: 0.9;
    outline: none;
    text-align: center;
    min-height: 40px;
}

/* SOCIAL */
.social-icon{
    font-size: 20px;
}
.instagram{
    /*
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
   */
    color: #d6249f;
    border-radius: 5px;
}
.facebook{
    color: #3b5998;
    border-radius: 5px;
}
.youtube{
    color:#FF0000
}
.twitter{
    color: #1DA1F2;
}
.hideDestop{
    display: none;
}
/*==========  Non-Mobile First Method  ==========*/

	/* Large Devices, Wide Screens */
	@media only screen and (max-width : 1200px) {

	}

	/* Medium Devices, Desktops */
	@media only screen and (max-width : 992px) {
        .trigger-menu{
            display: inline-block;
        }
        .trigger-search{
            display: none;
        }
        .menu{
            display: block;
            position: fixed;
            left: 0;
            top: 80px;
            width: 100%;
            background: rgba(17, 10, 10);
            height: 100%;
            transition: all 0.3s ease-out; /* add transition property */
            max-height: 0; /* hide the menu initially */
            overflow: hidden; /* hide the overflow content */
            z-index: 2;
            padding: 0;
        }
        .menu.show {
            max-height: 100%; /* set a maximum height for the menu */
        }
        .menu li{
            padding: 10px;
            border-bottom: 1px solid #212121;
            margin-right: 0;
        }
        .hideMobile{
            display: none;
        }
        .hideDestop{
            display: block;
        }
	}

	/* Small Devices, Tablets */
	@media only screen and (max-width : 768px) {

	}

	/* Extra Small Devices, Phones */ 
	@media only screen and (max-width : 480px) {

	}

	/* Custom, iPhone Retina */ 
	@media only screen and (max-width : 320px) {
		
	}